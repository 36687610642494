import { Form, FormValidationRules, RichTextField, useForm } from '@kit/components/Form';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useMentions } from '@hooks/useMentions';
import { convertQuillFormatToText, isQuilValueEmpty } from '@utils/quill';
import { useComments } from '@hooks/useComments';
import { CommentType, File } from '@generated/types/graphql';
import { Maximize2, Send } from 'react-feather';
import { UseFormReturn } from 'react-hook-form';
import { useModal } from '@common/PromiseModal';
import { FeedItem } from '@features/ProjectPortfolio/Project/History/FeedItem/types';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { useDrawersContext } from '@contexts/DrawersContext';
import { QueryParamsEnum, useQueryParamMutation } from '@hooks/useQueryParam';
import { ActionButtons, BodyWithActionButtons, CommentList, ExpandedContainer, SendIconButton } from './styled';

interface Props {
  initialValues?: { id?: number; comment: string; files: File[] };
  recordId: number;
  onClose?: () => void;
  type?: CommentType;
  workOrderId?: number;
  fileId?: number;
  systemId?: number;
  parentId?: number;
  parentItem?: FeedItem;
  size?: 'small' | 'default';
  children?: React.ReactNode;
  isExpandable?: boolean;
  expandSize?: 'small' | 'default';
}

interface FormValues {
  text: string;
}

export const CommentForm = forwardRef<HTMLDivElement, Props>(
  (
    {
      recordId,
      workOrderId,
      fileId,
      type = CommentType.Project,
      onClose,
      parentId,
      systemId,
      initialValues,
      size = 'default',
      parentItem,
      children,
      isExpandable = true,
      expandSize = 'default'
    },
    ref
  ) => {
    const { isLoading, getMentions } = useMentions(recordId);

    const { openDrawer } = useDrawersContext();

    const isEdit = Boolean(initialValues?.id);

    const [attachments, setAttachments] = useState<File[]>(initialValues?.files || []);

    const handleAttachFile = useCallback((file: File) => {
      setAttachments((prev) => [...prev, file]);
    }, []);

    const {
      create: { mutateAsync: createComment },
      update: { mutateAsync: updateComment }
    } = useComments();

    const { setParams } = useQueryParamMutation();

    const postForm = async (values: FormValues, form: UseFormReturn<FormValues>) => {
      if (isEdit) {
        await updateComment({
          id: initialValues.id,
          dto: { comment: convertQuillFormatToText(values.text), fileIds: attachments.map((file) => file.id) }
        });
      } else {
        await createComment({
          projectId: recordId,
          taskId: workOrderId,
          fileId,
          systemId,
          comment: convertQuillFormatToText(values.text),
          type,
          parentId,
          fileIds: attachments.map((file) => file.id)
        });

        setParams(
          {
            [QueryParamsEnum.FeedCursor]: undefined,
            [QueryParamsEnum.FeedId]: undefined
          },
          true
        );
      }

      form.reset({ text: '' });
      setAttachments([]);

      onClose?.();
    };

    const { handleSubmit, form } = useForm<FormValues>({
      onSubmit: postForm,
      defaultValues: initialValues?.comment ? { text: initialValues.comment } : {}
    });

    const rules = useMemo<FormValidationRules<FormValues>>(
      () => ({
        text: {
          isRequired: true,
          validate: (value) => {
            if (value && isQuilValueEmpty(value as string)) {
              return 'This is required';
            }

            return undefined;
          }
        }
      }),
      []
    );

    const {
      formState: { isSubmitting, errors },
      control
    } = form;

    const { openModal } = useModal();

    const handleExpandClick = useCallback(() => {
      const comment = form.getValues('text');
      form.reset({ text: '' });
      onClose?.();

      if (parentId) {
        openModal(
          ({ onClose: onModalClose }) => (
            <ExpandedContainer>
              <CommentList>{children}</CommentList>
              <CommentForm
                recordId={recordId}
                workOrderId={workOrderId}
                fileId={fileId}
                parentId={parentId}
                parentItem={parentItem}
                type={type}
                onClose={() => {
                  onModalClose();
                }}
                initialValues={{
                  comment,
                  files: attachments
                }}
                isExpandable={false}
              />
            </ExpandedContainer>
          ),
          { title: 'Reply to comment' }
        );
      } else {
        openModal(
          ({ onClose: onModalClose }) => (
            <ExpandedContainer>
              <CommentForm
                recordId={recordId}
                workOrderId={workOrderId}
                fileId={fileId}
                onClose={() => {
                  onModalClose();
                }}
                type={type}
                initialValues={{
                  comment,
                  files: attachments
                }}
                isExpandable={false}
              />
            </ExpandedContainer>
          ),
          { title: 'New comment' }
        );
      }
    }, [children, parentId, recordId, parentItem, type, workOrderId, fileId, form, openModal, onClose, attachments]);

    const handleKeyDown = useCallback<React.KeyboardEventHandler>(
      (event) => {
        const isMac = navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
        const isSubmitShortcut =
          (isMac && event.metaKey && event.key === 'Enter') || (!isMac && event.ctrlKey && event.key === 'Enter');

        if (isSubmitShortcut) {
          event.preventDefault(); // Prevent new line or other editor behavior
          handleSubmit();
        }
      },
      [handleSubmit]
    );

    if (isLoading) {
      return null;
    }

    return (
      <Form rules={rules} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <BodyWithActionButtons size={size} ref={ref}>
          <RichTextField
            name="text"
            control={control}
            placeholder="Type your comment here..."
            getMentions={getMentions}
            autoFocus
            isAttachmentAllowed
            attachments={attachments}
            onAttachmentsChange={setAttachments}
            onAttach={handleAttachFile}
            openDrawer={openDrawer} // otherwise there is webpack error if use this context directly in richeditor compoennt
          />
          <ActionButtons size={size} isBodyWithError={Boolean(errors.text)}>
            {isExpandable &&
              (expandSize === 'default' ? (
                <Button
                  size={ButtonSize.Small}
                  isUpperCase={false}
                  variant={ButtonVariant.Flat}
                  onClick={handleExpandClick}
                >
                  <Maximize2 size="16px" />
                  Expand
                </Button>
              ) : (
                <IconButton size={ButtonSize.Small} variant={ButtonVariant.Flat} onClick={handleExpandClick}>
                  <Maximize2 size="16px" />
                </IconButton>
              ))}

            {size === 'default' && (
              <Button variant={ButtonVariant.Flat} onClick={onClose}>
                Cancel
              </Button>
            )}

            {size === 'small' && (
              <SendIconButton disabled={isSubmitting} type="submit">
                <Send size="12px" color="#fff" />
              </SendIconButton>
            )}

            {size === 'default' && (
              <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
                {isEdit ? 'Save' : 'Send'}
              </Button>
            )}
          </ActionButtons>
        </BodyWithActionButtons>
      </Form>
    );
  }
);
